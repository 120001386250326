<template>
  <div class="" style="width: 100%;">
    <v-container fluid>
      <v-row>
        <v-col class='col-md-8 d-flex flex-row align-center'>
          <v-btn text class="mx-2" @click="()=>{this.$router.go(-1)}">
            <v-icon dark>mdi-arrow-left</v-icon>
          </v-btn>
          <h1><span v-if="!this.isNewPO">Edit</span> <span v-else>New </span>{{' '+this.singular}}<span v-if="this.data.firstName">:</span> {{this.data.firstName}} {{this.data.lastName}}</h1>
        </v-col>
        <v-col class="col-md-4">
          <v-progress-circular
            indeterminate
            color="green"
            v-if="loader"
          ></v-progress-circular>
        </v-col>
      </v-row>
      <v-row v-if="!loader">
        <v-col cols="2">
          <v-autocomplete
            v-model="purchaseOrder.supplierId"
            :items="suppliers"
            item-text="name"
            item-value="id"
            outlined
            dense
            :disabled="supplierDisabledCriteria"
            @change="supplierSelected"
            label="Supplier"
          />
          <div v-if="purchaseOrder.supplierId" style="height:900px; overflow-y: scroll;" class="d-flex flex-column">
            <h4>Products from {{this.selectedSupplier.name}}</h4>
            <div v-for="(item,index) in supplierProducts" :key="index">
              <div style="padding: 10px; background-color:rgba(0,0,0,0.09); margin-bottom: 3px; border-radius: 7px;" class="d-flex flex-row align-center justify-space-between">
                <div>
                  <span>{{item.name}}</span>
                  <br>
                  <span><b>ID:</b> {{item.id}}</span>
                  <br>
                  <span><b>Model #:</b> {{item.sku}}</span>
                  <br>
                  <span><b>Cost Price:</b> {{formatPrice(item.Suppliers[0].ProductSupplierJoin.costPrice)}}</span>
                </div>
                <v-btn @click="addLineItem(item)" fab x-small color="info"><v-icon>mdi-chevron-right</v-icon></v-btn>
              </div>
            </div>
          </div>
        </v-col>
        <v-col class="col-8" style="background-color: rgba(0,0,0,0.05); border-radius: 10px; padding: 20px;">          
          <div class="d-flex flex-column align-space-between justify-space-between" style="height: 800px;">
            <div style="width: 100%;">
              <!-- header -->
              <div class="d-flex flex-row justify-space-between">
                <div class="d-flex flex-column" style="width: 50%; margin-bottom: 10px;">
                  <!-- <img src="" alt=""> -->
                  <h1 class="d-flex flex-row align-center">
                    <div v-if="updateStatus==2" style="margin-right: 10px; background-color: red; border-radius: 15px; width: 15px; height: 15px;"></div>
                    <div v-if="updateStatus==0" style="margin-right: 10px; background-color: green; border-radius: 15px; width: 15px; height: 15px;"></div>
                    <div v-if="updateStatus==1" style="margin-right: 10px; background-color: orange; border-radius: 15px; width: 15px; height: 15px;"></div>
                    Purchase Order<span style="margin-left: 10px;">#{{purchaseOrder.id}}</span>
                  </h1> 
                </div>
                <div class="d-flex flex-column align-end" style="width: 50%;">
                  <span>{{currentFormattedDate}}</span>
                </div>
              </div>
              <div style="width: 100%; margin-top: 10px;" class="d-flex flex-row justify-space-between">
                <div v-if="purchaseOrder.supplierId" class="d-flex flex-column" style="width:50%;">
                  <span>
                    <b>Supplier</b>
                    <!-- <v-btn v-if="!supplierEditing" style="margin-left: 5px;" color="info" x-small @click="setSupplierEditing(true)">Edit</v-btn>
                    <v-btn v-if="supplierEditing" style="margin-left: 5px;" color="success" x-small @click="saveSupplierInfo()">Save</v-btn>
                    <v-btn v-if="supplierEditing " style="margin-left: 5px;" color="info" x-small @click="setSupplierEditing(false)">Cancel</v-btn> -->
                  </span>
                  <div v-if="supplierEditing">
                    <v-text-field v-if="tempSupplierInfo.name" style="width: 90%;" dense v-model="tempSupplierInfo.name"></v-text-field>
                    <v-text-field v-if="tempSupplierInfo.address" style="width: 90%;" dense v-model="tempSupplierInfo.address"></v-text-field>
                    <v-text-field v-if="tempSupplierInfo.phone" style="width: 90%;" dense v-model="tempSupplierInfo.phone"></v-text-field>
                    <v-text-field v-if="tempSupplierInfo.email" style="width: 90%;" dense v-model="tempSupplierInfo.email"></v-text-field>
                  </div>
                  <div v-else class="d-flex flex-column">
                    <span v-if="purchaseOrder.supplierInfo.name">{{purchaseOrder.supplierInfo.name}}</span>
                    <span v-if="purchaseOrder.supplierInfo.address">{{purchaseOrder.supplierInfo.address}}</span>
                    <span v-if="purchaseOrder.supplierInfo.phone">{{purchaseOrder.supplierInfo.phone}}</span>
                    <span v-if="purchaseOrder.supplierInfo.email">{{purchaseOrder.supplierInfo.email}}</span>
                  </div>
                </div>
                <div v-else class="d-flex flex-column" style="width:50%;">
                  <span><b>Supplier</b></span>
                  <span>-</span>
                </div>
                <div v-if="purchaseOrder.shipToInfo" class="d-flex flex-column" style="width:50%;">
                  <span>
                    <b>Ship To</b>
                    <v-btn v-if="!shipToEditing" style="margin-left: 5px;" color="info" x-small @click="setShipToEditing(true)">Edit</v-btn>
                    <v-btn v-if="shipToEditing" style="margin-left: 5px;" color="success" x-small @click="saveShipToInfo()">Save</v-btn>
                    <v-btn v-if="shipToEditing " style="margin-left: 5px;" color="info" x-small @click="setShipToEditing(false)">Cancel</v-btn>
                  </span>
                  <div v-if="shipToEditing">
                    <v-text-field style="width: 90%;" dense v-model="tempShipToInfo.name"></v-text-field>
                    <v-text-field style="width: 90%;" dense v-model="tempShipToInfo.address"></v-text-field>
                    <v-text-field style="width: 90%;" dense v-model="tempShipToInfo.phone"></v-text-field>
                    <v-text-field style="width: 90%;" dense v-model="tempShipToInfo.email"></v-text-field>
                  </div>
                  <div v-else class="d-flex flex-column">
                    <span>{{purchaseOrder.shipToInfo.name}}</span>
                    <span>{{purchaseOrder.shipToInfo.address}}</span>
                    <span>{{purchaseOrder.shipToInfo.phone}}</span>
                    <span>{{purchaseOrder.shipToInfo.email}}</span>
                  </div>
                </div>
                <div v-else class="d-flex flex-column" style="width:50%;">
                  <span><b>Ship To</b></span>
                  <span>-</span>
                </div>
              </div>
            </div>
            <div style="height: 500px; border: 2px solid rgba(0,0,0,0.15); border-radius: 10px; margin-top: 20px; padding: 20px; overflow-y: scroll;">
              <!-- body -->
                <v-simple-table style="height: 100%; background-color: rgba(0,0,0,0)">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th></th>
                      <th class="text-left">
                        Item No.
                      </th>
                      <th class="text-left">
                        Item Name
                      </th>
                      <th class="text-left">
                        Unit Price
                      </th>
                      <th class="text-left">
                        Qty
                      </th>
                      <th class="text-right">
                        Line Total
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in purchaseOrder.PurchaseOrderLineItems" :key="i">
                      <td><v-btn icon dark small color="red" @click="removeLineItem(item.id)"><v-icon >mdi-close</v-icon></v-btn></td>
                      <td>{{item.sku}}</td>
                      <td>{{item.productName}}</td>
                      <td>
                        <v-text-field
                          outlined
                          class="mt-5" 
                          style="width:100px;" 
                          dense 
                          type="number" 
                          :rules="quantityRules" 
                          v-model="item.unitPrice"
                          min="0"
                          @change="updateLineItem(item)">
                        </v-text-field>
                      </td>
                      <td>
                        <v-text-field
                          outlined
                          class="mt-5" 
                          style="width:100px;" 
                          dense 
                          type="number" 
                          :rules="quantityRules" 
                          v-model="item.quantity"
                          min="1"
                          @change="updateLineItem(item)">
                        </v-text-field>
                      </td>
                      <td class="text-right"><span v-if="item.metadata.lineTotal&&item.metadata.lineTotal>-1">{{formatPrice(item.metadata.lineTotal)}}</span><span v-else>-</span></td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
            <div class="d-flex flex-row justify-end" style="width: 100%; margin-top: 10px;">
              <div class="d-flex flex-column align-end">
                <b>Subtotal</b>
                <b>
                  <span>
                    <v-btn style="margin-left: 5px;" color="warning" x-small @click="()=>{this.$router.push({path: '/taxes/view/'})}">Edit</v-btn>
                  </span> 
                  Tax ({{this.taxRate}}%)</b>
                <b>Total</b>
              </div>
              <div style="width: 120px; padding-right:20px;" class="d-flex flex-column align-end">
                <span class="d-flex flex-column align-start">
                  <div>{{formatPrice(purchaseOrder.subtotal)}}</div>
                  <div>{{formatPrice(purchaseOrder.subtotal*(taxRate/100)||0)}}</div>
                  <div>{{formatPrice(purchaseOrder.grandTotal)}}</div>
                </span>
              </div>
            </div>
            <div style="margin-top: 20px; height: 100px;">
              <!-- footer -->
              <v-btn style="margin-right:5px;" color="error" @click="openVoidDialog()" :disabled="purchaseOrder.status!==-1">Void</v-btn>
              <v-btn @click="signA()" color="success" :disabled="!supplierDisabledCriteria">Sign Purchase Order</v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
      </v-row>
    </v-container>
    <v-dialog
      v-model="voidDialog"
      max-width="490"
    >
      <v-card>
        <v-card-title class="text-h5">
          Delete this Purchase Order Draft?
        </v-card-title>
        <v-card-text>You will not be able to recover it once deleted.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="voidDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="error"
            @click="confirmVoid()"
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="signDialog"
      max-width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          Are you sure you wish to sign this Purchase order?
        </v-card-title>
        <v-card-text>Please ensure all fields are correct. Your identity will be linked to this document.</v-card-text>
        <v-card-text>Enter your password to confirm.</v-card-text>
        <v-text-field label="Password" placeholdler="Password" type="password" outlined style="width: 300px; margin: 20px;" v-model="userPassword"></v-text-field>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="signDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="error"
            :disabled="!userPassword"
            @click="signB()"
          >
            Confirm Signature
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import axios from 'axios';
  import moment from 'moment';
  import {mapGetters} from 'vuex'
  export default {
    data () {
      return {
        loader: false,

        snackObj: {
          state: false,
          color: '',
          text: ''
        },

        max25chars: v => v.length <= 25 || 'Input too long!',
        updateStatus: 0,
        quantityRules: [
          v => v>=1 || 'Must be more than or equal 1'
        ],

        data: {},
        userTypes: [],
        products: [],
        singular: "Purchase Order",
        singularLower: "purchaseorder",
        plural: "Purchase Orders",
        pluralLower: "purchaseorders",
        suppliers: [],
        supplierProducts: [],
        purchaseOrder: {
          PurchaseOrderLineItems: [],
          subtotal: 0, 
          grandTotal: 0, 
          supplierInfo: {}, 
          shipToInfo: {
            name: "Teelucksingh's Furniture Limited",
            address: "57 High Street, Princes Town",
            phone: '3332123',
            email: 'teelucksinghs@gmail.com',
        }},
        valid: false,
        taxRate: 10,
        created: false,
        isNewPO: "",
        supplierEditing: false,
        shipToEditing: false,
        tempSupplierInfo: {},
        tempShipToInfo: {},
        renderKey: 0,
        voidDialog: false,
        signDialog: false,
        userPassword: null,
        updateStateValue: 0,
      }
    },
    async mounted(){
      try{
        this.loader = true;
        if(this.$route.params.id){
          let po = await axios.get(`${this.getEndpoint}/api/${this.pluralLower}/${this.$route.params.id}`);
          if(po.data.error) throw po.data.error
          po.data.data.PurchaseOrderLineItems.sort((a,b) => (a.createdAt > b.createdAt) ? 1 : ((b.createdAt > a.createdAt) ? -1 : 0))
          this.purchaseOrder = po.data.data
          if(this.purchaseOrder.status > 0) await this.$router.push({path: `/purchaseorders/view/${this.$route.params.id}`})
          this.purchaseOrder.PurchaseOrderLineItems.forEach(x=>{
            x.metadata.lineTotal = parseInt(x.quantity)*parseFloat(x.unitPrice)
          })
          let suppliers = await axios.get(`${this.getEndpoint}/api/suppliers`);
          if(suppliers.data.error) throw suppliers.data.error
          this.suppliers = suppliers.data.data
          if(this.purchaseOrder.supplierId){            
            let products = await axios.get(`${this.getEndpoint}/api/products/bySupplier/${this.purchaseOrder.supplierId}`);
            if(products.data.error) throw products.data.error
            this.supplierProducts = products.data.data
            this.supplierProducts.forEach(x=>{
              x.quantity=1
            })
            this.purchaseOrder.subtotal = this.subtotal
            await this.updateTotals()
            return;
          }
        }
        await this.updatePurchaseOrder();
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error, "error");
      }
      finally {
        this.loader = false;
      }
    },
    computed: {
      supplierDisabledCriteria(){
        return this.purchaseOrder.PurchaseOrderLineItems.length>0
      },
      selectedSupplier(){
        if(this.suppliers.length>0){
          return this.suppliers.find(x=>x.id==this.purchaseOrder.supplierId)
        }
        return "-"
      },
      currentFormattedDate(){
        return moment().format("DD/MM/YYYY")
      },
      ...mapGetters(['getWorkingId', 'getEndpoint'])
    },
    methods: {
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      },
      signA(){
        if(this.supplierEditing){
          this.snackText = "You are currently editing the supplier info. Please Cancel or Save your changes."
          this.snackBar = true;
        }
        if(this.shipToEditing){
          this.snackText = "You are currently editing the Ship To information. Please Cancel or Save your changes."
          this.snackBar = true;
        }
        for(let item of this.purchaseOrder.PurchaseOrderLineItems){
          if(item.quantity<=0){
            this.snackText = "Your quantity is invalid for "+item.name;
            this.snackBar = true;
            return;
          }
        }
        if(!this.supplierEditing && ! this.shipToEditing) 
          this.signDialog = true;
      },
      async signB(){
        try {
          this.loader = true
          if(this.purchaseOrder.status > 0) throw "Cannot Re-Sign Purchase Order"
          if(!this.userPassword) throw "Password Needed to verify the signature."
          let res = await axios.post(`${this.getEndpoint}/api/${this.pluralLower}/sign/${this.purchaseOrder.id}`, {password: this.userPassword})
          if(res.data.error) throw res.data.error
          this.snack("Signed!")
          await this.$router.push({path: `/purchaseorders/view/${this.$route.params.id}`})
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        }finally{
          this.loader = false
        }
      },
      async addLineItem(item){
        try {
          if((this.purchaseOrder.PurchaseOrderLineItems.filter(x=>x.productId==item.id)).length>0) throw "Item Already in Purchase Order."  
          this.updateStateValue = 1
          let newItem = {
            poId: this.$route.params.id,
            productId: item.id,
            productName: item.name,
            sku: item.sku,
            unitPrice: item.Suppliers[0].ProductSupplierJoin.costPrice,
            quantity: item.quantity,
            metadata: {
              lineTotal: item.lineTotal
            },
          }
          let res = await axios.post(`${this.getEndpoint}/api/purchaseorders/lineItem/${this.purchaseOrder.id}`, newItem);
          if(res.data.error) throw res.data.error
          if(!this.purchaseOrder.PurchaseOrderLineItems) this.purchaseOrder.PurchaseOrderLineItems = []
          if(!res.data.data.metadata.lineTotal) res.data.data.metadata.lineTotal = 0
          res.data.data.metadata.lineTotal = parseInt(res.data.data.quantity)*parseFloat(res.data.data.unitPrice)
          this.purchaseOrder.PurchaseOrderLineItems.push(res.data.data)
          await this.updateTotals()
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
          this.updateStateValue = 2
        } finally {
          this.updateStateValue = 0
        }
      },
      async removeLineItem(e){
        try {
          this.updateStateValue = 1
          let res = await axios.delete(`${this.getEndpoint}/api/purchaseorders/lineItem/${e}`)
          if(res.data.error) throw res.data.error
          let i = (this.purchaseOrder.PurchaseOrderLineItems.findIndex(x=>x.id===e))
          if(i == -1) throw "Item Could Not Be Found. Refresh this page."
          this.purchaseOrder.PurchaseOrderLineItems.splice(i,1)
          if(!this.purchaseOrder.PurchaseOrderLineItems) this.purchaseOrder.PurchaseOrderLineItems = []
        } catch (error) {
          console.log(error)
          this.snack(error)
          this.updateStateValue = 2
        } finally {
          this.updateStateValue = 0
        }
      },
      async updateLineItem(p){
        try {
          if(!p.quantity) throw "Quantity Cannot be Blank."
          if(!p.unitPrice) throw "Unit Price Cannot be Blank."
          this.updateStateValue = 1
          let res = await axios.put(`${this.getEndpoint}/api/purchaseorders/lineItem/${p.id}`, p)
          if(res.data.error) throw res.data.error
          if(!res.data.data.metadata.lineTotal) res.data.data.metadata.lineTotal = 0
          res.data.data.metadata.lineTotal = parseInt(res.data.data.quantity)*parseFloat(res.data.data.unitPrice)
          let ix = this.purchaseOrder.PurchaseOrderLineItems.findIndex(x=>x.id==p.id)
          ix==-1?null:this.purchaseOrder.PurchaseOrderLineItems.splice(ix, 1, res.data.data)
          this.updateTotals()
        } catch (error) {
          console.log(error)
          this.snack(error)
          this.updateStateValue = 2
        } finally {
          this.updateStateValue = 0
        }
      },
      async updateTotals(){
        if(!this.purchaseOrder.PurchaseOrderLineItems || this.purchaseOrder.PurchaseOrderLineItems.length===0) return
        this.purchaseOrder.PurchaseOrderLineItems.forEach(item=>{
          if(!item.metadata) item.metadata = {lineTotal: 0}
          if(!item.metadata.lineTotal) item.metadata.lineTotal = 0
        })
        this.purchaseOrder.subtotal = this.purchaseOrder.PurchaseOrderLineItems.reduce((a,x)=>a+parseFloat(x.metadata.lineTotal),0)
        this.purchaseOrder.grandTotal = ((this.taxRate/100)*this.purchaseOrder.subtotal) + this.purchaseOrder.subtotal
        this.$forceUpdate()
      },
      async supplierSelected(){
        try {
          if(this.purchaseOrder.supplierId == null) throw 'Cleared the supplier selection.'
          let products = await axios.get(`${this.getEndpoint}/api/products/bySupplier/${this.purchaseOrder.supplierId}`);
          if(products.data.error) throw products.data.error
          this.supplierProducts = products.data.data
          this.supplierProducts.forEach(x=>{x.quantity=1})          
          this.purchaseOrder.supplierInfo = this.suppliers.find(x=>x.id==this.purchaseOrder.supplierId)
          this.updatePurchaseOrder()
        } catch (error) { 
          console.error(error)
          this.snack(error.msg || error, "error");
        }
      },
      setSupplierEditing(bool){
        if(bool){
          if(this.isNewPO) this.purchaseOrder.status = undefined
          else this.purchaseOrder.status = 0
          this.tempSupplierInfo = {...this.purchaseOrder.supplierInfo}
        }else{
          if(this.isNewPO) this.purchaseOrder.status = undefined
          else{
            if(this.shipToEditing){
              this.purchaseOrder.status = 0
            }else{
              this.purchaseOrder.status = 0
            }
          }
          this.tempSupplierInfo = {}
        }
        this.supplierEditing = bool
      },
      async saveSupplierInfo(){
        try {
          this.purchaseOrder.supplierInfo = {...this.tempSupplierInfo}
            let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/updateDraft/${this.purchaseOrder.id}`, this.purchaseOrder)
            if(res.status==200){
              this.setSupplierEditing(false)
          }
          await this.updatePurchaseOrder();
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        }
      },
      setShipToEditing(bool){
        if(bool){
          if(this.isNewPO) this.purchaseOrder.status = undefined
          else this.purchaseOrder.status = 0
          this.tempShipToInfo = {...this.purchaseOrder.shipToInfo}
        }else{
          if(this.isNewPO) this.purchaseOrder.status = undefined
          else {
            if(this.supplierEditing){
              this.purchaseOrder.status = 0
            }else{
              this.purchaseOrder.status = 'draftSaved'
            }
          }
          this.tempShipToInfo = {}
        }
        this.shipToEditing = bool
      },
      async saveShipToInfo(){
        try {
          this.purchaseOrder.shipToInfo = {...this.tempShipToInfo}
          let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/updateDraft/${this.purchaseOrder.id}`, this.purchaseOrder)
          if(res.data.error) throw res.data.error
          this.setShipToEditing(false)
          await this.updatePurchaseOrder();
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        }
      },
      async updatePurchaseOrder(){
        try {
          this.updateStatus = 1
          let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/updateDraft/${this.purchaseOrder.id}`, this.purchaseOrder)
          if(res.data.error) throw res.data.error
          // res.data.data.PurchaseOrderLineItems.sort((a,b) => (a.createdAt > b.createdAt) ? 1 : ((b.createdAt > a.createdAt) ? -1 : 0))
          // this.purchaseOrder = res.data.data
          // this.purchaseOrder.subtotal = this.subtotal
          this.updateStatus = 0
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
          this.updateStatus = 2
        }
      },
      openVoidDialog(){
        this.voidDialog = true
      },
      async confirmVoid(){
        try {
          this.voidDialog = false
          let res = await axios.put(`${this.getEndpoint}/api/${this.pluralLower}/void/${this.purchaseOrder.id}`)
          if(res.data.error) throw res.data.error
          await this.$router.push({path: "/purchasing"})
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        }
      },
      formatPrice(value){
        if(!value) return "";
        let formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD'
        });
        return formatter.format(parseFloat(value));
      },
    }
  }
</script>
