<template>
  <div class="" style="width: 100%;">
    <v-container fluid>
      <v-row>
        <v-col class='d-flex flex-row align-center'>
          <v-btn text class="mx-2" @click="()=>{this.$router.go(-1)}">
            <v-icon dark>mdi-arrow-left</v-icon>
          </v-btn>
          <h1>Customer: {{this.cus.name}}</h1>
          <v-progress-circular
            indeterminate
            color="green"
            v-if="loader"
            style="margin-left: 10px;"
          ></v-progress-circular>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="isEditable">
          <v-btn @click="updateCustomer">Save</v-btn>
          <v-btn v-if="isAllowed('customer', 'd')" style="margin-left: 10px;" class="error" @click="deleteCustomer">Delete</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn v-if="isAllowed('customer', 'u')" @click="isEditable = !isEditable" color='info'>
            <span v-if="this.isEditable">Cancel</span>
            <span v-else>Edit Customer Information</span>
          </v-btn>
        </v-col>
      </v-row>
      <span v-if="isEditable">
        <v-form :disabled="!isEditable">
          <v-row>
            <v-col>
              <v-text-field
                  label="Name"
                  outlined
                  v-model="cus.name"
              />
              <v-text-field
                  label="Phone"
                  outlined
                  v-model="cus.phone"
              />
              <v-text-field
                  label="Email"
                  outlined
                  v-model="cus.email"
              />
              <!-- <v-text-field
                  label="Contact Name"
                  outlined
                  v-model="cus.contact.name"
              />
              <v-text-field
                  label="Contact Phone"
                  outlined
                  v-model="cus.contact.phone"
              /> -->
            </v-col>
          </v-row>
          <v-row>
            <v-col>
            <span class="d-flex flex-row align-center mb-2">
              <h2>Addresses</h2>
              <v-btn v-if="isEditable" @click="saveCustomAddressDialog=true" fab small class="ml-2" color="info"><v-icon>mdi-plus</v-icon></v-btn>
            </span>
              <v-card v-for="(addr, i) in cus.addresses" class="d-flex flex-column" outlined :key="i">
                <v-card-title class="d-flex flex-row justify-space-between">
                  <span>{{addr.name}}</span>
                  <v-btn v-if="isAllowed('customer', 'u')" fab x-small class="error" @click="deleteAddress(i)"><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>
                <v-card-text class="d-flex flex-column">
                <span class="d-flex flex-row">
                  <v-text-field label="Address Line 1" v-model="addr.line1" outlined dense class="mx-2"/>
                  <v-text-field label="Address Line 2" v-model="addr.line2" outlined dense class="mx-2"/>
                </span>
                  <span class="d-flex flex-row">
                  <v-text-field label="City" v-model="addr.city" outlined dense class="mx-2"/>
                  <v-text-field label="Country" v-model="addr.country" outlined dense class="mx-2"/>
                </span>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </span>
      <v-row v-if="!this.loader">
        <v-col>
          <h2>Account Summary</h2>
          <span class="d-flex flex-row">
            <span class="ml-3 d-flex flex-column justify-space-between">
              <span v-if="this.cus.Orders">Total Purchases: {{formatPrice(this.cus.Orders.reduce((acc,x)=>acc+(x.metadata.grandTotal||0),0))}}</span>
              <span v-if="this.paymentTable">Total Payments: {{formatPrice(this.paymentTable.items.reduce((acc,x)=>acc+parseFloat(x.amount),0))}}</span>
              <span v-if="this.paymentTable">Balance: {{formatPrice(this.cus.Orders.reduce((acc,x)=>acc+(x.metadata.grandTotal||0),0)-this.paymentTable.items.reduce((acc,x)=>acc+parseFloat(x.amount),0))}}</span>
            </span>
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card outlined>
            <v-card-text>
              <h3>Orders</h3>
              <v-data-table
                  :headers="purchaseTable.headers"
                  :items="purchaseTable.items"
                  :items-per-page="-1"
                  class="elevation-0"
                  @click:row="purchaseTableRowClick"
              >
                <template v-slot:item.createdAt="{ item }">
                  <span>{{formatDate(item.createdAt) }}</span>
                </template>
                <template v-slot:item.status="{ item }">
                  <span v-if="item.status===-1" class="v-btn warning pa-1" style="font-size: 10px">{{parseOrderStatus(item.status)}}</span>
                  <span v-if="item.status===0" class="v-btn orange pa-1" style="font-size: 10px">{{parseOrderStatus(item.status)}}</span>
                  <span v-if="item.status===1" class="v-btn success pa-1" style="font-size: 10px">{{parseOrderStatus(item.status)}}</span>
                </template>
                <template v-slot:item.createdBy="{ item }">
                  <span>{{lookupUsername(item.createdBy) }}</span>
                </template>
                <template v-slot:item.numItems="{ item }">
                  <span>{{ item.numItems }}</span>
                </template>
                <template v-slot:item.amount="{ item }">
                  <span>{{formatPrice(item.amount) }}</span>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card outlined>
            <v-card-text>
              <h3>Returns</h3>
              <v-data-table
                  :headers="returnTable.headers"
                  :items="returnTable.items"
                  :items-per-page="-1"
                  class="elevation-0"
                  @click:row="returnTableRowClick"
              >
                <template v-slot:item.createdAt="{ item }">
                  <span>{{formatDate(item.createdAt) }}</span>
                </template>
                <template v-slot:item.status="{ item }">
                  <span v-if="item.status===-1" class="v-btn warning pa-1" style="font-size: 10px">{{parseOrderStatus(item.status)}}</span>
                  <span v-if="item.status===0" class="v-btn orange pa-1" style="font-size: 10px">{{parseOrderStatus(item.status)}}</span>
                  <span v-if="item.status===1" class="v-btn success pa-1" style="font-size: 10px">{{parseOrderStatus(item.status)}}</span>
                </template>
                <template v-slot:item.createdBy="{ item }">
                  <span>{{lookupUsername(item.createdBy) }}</span>
                </template>
                <template v-slot:item.numItems="{ item }">
                  <span>{{ item.numItems }}</span>
                </template>
                <template v-slot:item.amount="{ item }">
                  <span>{{formatPrice(item.amount) }}</span>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card outlined>
            <v-card-text>
              <h3>Payments</h3>
              <v-data-table
                  :headers="paymentTable.headers"
                  :items="paymentTable.items"
                  :items-per-page="-1"
                  class="elevation-0"
                  @click:row="paymentTableRowClick"
              >
                <template v-slot:item.createdAt="{ item }">
                  <span>{{formatDate(item.createdAt) }}</span>
                </template>
                <template v-slot:item.receivedBy="{ item }">
                  <span>{{lookupUsername(item.receivedBy) }}</span>
                </template>
                <template v-slot:item.amount="{ item }">
                  <span>{{formatPrice(item.amount) }}</span>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card outlined>
            <v-card-text>
              <h3>Credit Notes</h3>
              <v-data-table
                  :headers="creditNoteTable.headers"
                  :items="creditNoteTable.items"
                  :items-per-page="-1"
                  class="elevation-0"
                  @click:row="creditNoteTableRowClick"
              >
                <template v-slot:item.createdAt="{ item }">
                  <span>{{formatDate(item.createdAt) }}</span>
                </template>
                <template v-slot:item.status="{ item }">
                  <span v-if="item.status===-1" class="v-btn warning pa-1" style="font-size: 10px">{{parseCNStatus(item.status)}}</span>
                  <span v-if="item.status===0" class="v-btn orange pa-1" style="font-size: 10px">{{parseCNStatus(item.status)}}</span>
                  <span v-if="item.status===1" class="v-btn info pa-1" style="font-size: 10px">{{parseCNStatus(item.status)}}</span>
                  <span v-if="item.status===2" class="v-btn success pa-1" style="font-size: 10px">{{parseCNStatus(item.status)}}</span>
                </template>
                <template v-slot:item.issuedBy="{ item }">
                  <span>{{lookupUsername(item.issuedBy) }}</span>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog scrollable v-model="saveCustomAddressDialog" width="500">
      <v-card>
        <v-card-title class="text-h5">
          Save Address
        </v-card-title>
        <v-card-text>
          <v-text-field type="text" dense outlined label="Name of Address" v-model="newAddress.name"/>
          <span class="d-flex flex-row">
            <v-text-field label="Address Line 1" v-model="newAddress.line1" outlined dense class="mx-2"/>
            <v-text-field label="Address Line 2" v-model="newAddress.line2" outlined dense class="mx-2"/>
          </span>
          <span class="d-flex flex-row">
            <v-text-field label="City" v-model="newAddress.city" outlined dense class="mx-2"/>
            <v-text-field label="Country" v-model="newAddress.country" outlined dense class="mx-2"/>
          </span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error" text @click="closeCustomAddressDialog">
            Cancel
          </v-btn>
          <v-btn :disabled="!newAddress.name" class="success" text @click="saveCustomAddress">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="viewPaymentDialog.isOpen" max-width="490">
      <v-card>
        <v-card-title class="text-h5">
          View Payment
        </v-card-title>
        <v-card-text>
          <p>Order #: <router-link :to="'/orders/view/'+viewPaymentDialog.paymentToDisplay.orderId">{{viewPaymentDialog.paymentToDisplay.orderId}}</router-link></p>
          <p>Date Received: {{formatDate(viewPaymentDialog.paymentToDisplay.createdAt)}}</p>
          <p>Received By: {{lookupUsername(viewPaymentDialog.paymentToDisplay.receivedBy)}}</p>
          <p>Amount: {{formatPrice(viewPaymentDialog.paymentToDisplay.amount)}}</p>
          <p>Payment Type: {{lookupPaymentType(viewPaymentDialog.paymentToDisplay.paymentType)}}</p>
          <p v-if="lookupPaymentType(viewPaymentDialog.paymentToDisplay.paymentType)=='Cheque'">Cheque Number: {{viewPaymentDialog.paymentToDisplay.metadata.chequeNumber}}</p>
          <span v-if="lookupPaymentType(viewPaymentDialog.paymentToDisplay.paymentType)=='Credit Note'">
            <span>Credit Notes Used: </span>
            <span v-for="cn in viewPaymentDialog.paymentToDisplay.metadata.creditNotes" :key="cn.id">
              {{cn}}
            </span>
          </span>
          <p v-if="viewPaymentDialog.paymentToDisplay.cnMessage">{{viewPaymentDialog.paymentToDisplay.cnMessage}}</p>
        </v-card-text>
        <v-card-actions>
          <v-btn small text color="error" @click="closeViewPaymentDialog">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="viewCNDialog.isOpen" max-width="490">
      <v-card>
        <v-card-title class="text-h5">
          View CN # {{viewCNDialog.cn.id}}
        </v-card-title>
        <v-card-text class="d-flex flex-column">
          <span>Customer: {{this.cus.name}}</span>
          <span>Date Issued: {{formatDate(viewCNDialog.cn.createdAt)}}</span>
          <span>Issued By: {{lookupUsername(viewCNDialog.cn.issuedBy)}}</span>
          <span>Expires At: {{formatDate(viewCNDialog.cn.expiresAt)}}</span>
          <span>Amount: {{formatPrice(viewCNDialog.cn.amount)}}</span>
          <p v-if="viewCNDialog.cn.metadata&&viewCNDialog.cn.metadata.returnId">This CN was issued from Return # {{viewCNDialog.cn.metadata.returnId}}</p>
          <p v-if="viewCNDialog.cn.metadata&&viewCNDialog.cn.metadata.paymentId">This CN was automatically issued from the balance on a previously redeemed CN, with Transaction # {{viewCNDialog.cn.metadata.paymentId}}</p>
        </v-card-text>
        <v-card-actions>
          <v-btn v-if="getGlobalValue('PRINT_SYSTEM_ALLOW_CLOUD_PRINT')==='true'" class="ml-2" fab x-small color="info" @click="openCloudPrintDialog()"><v-icon>mdi-cloud-print</v-icon></v-btn>
              <v-btn v-if="getGlobalValue('PRINT_SYSTEM_ALLOW_DIRECT_PRINT')==='true'" class="ml-2" fab x-small color="info" @click="printDirect('creditNote',{id:viewCNDialog.cn.id})"><v-icon>mdi-printer</v-icon></v-btn>
              <v-btn :loading="printPreviewDialog.loading"  class="ml-2" fab x-small color="info" @click="printPreview('creditNote', {id:viewCNDialog.cn.id})"><v-icon>mdi-printer-eye</v-icon></v-btn>
          <v-btn text color="error" @click="closeViewCNDialog">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="printPreviewDialog.isOpen"
      width="11in"
      scrollable
    >
      <v-card v-if="printPreviewDialog.data">
        <v-card-title class="text-h5">Preview</v-card-title>
        <v-card-text>
          <div id="orderPreview" v-html="printPreviewDialog.data.job.htmlToPrint"></div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closePrintPreviewDialog">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import axios from 'axios';
  import {mapGetters} from "vuex";
  export default {
    data () {
      return {
        deleteDialog: false,
        deleteConfirmed: true,
        loader: true,

        snackObj: {
          state: false,
          color: '',
          text: ''
        },

        max25chars: v => v.length <= 25 || 'Input too long!',
        editMode: false,
        cus: {
          contact: {},
          addresses: []
        },
        isEditable: false,
        saveCustomAddressDialog: false,
        newAddress: {},
        purchaseTable: {
          headers: [
            {text: 'Order #', align: 'start', value: 'id'},
            { text: 'Status', value: 'status' },
            { text: 'Date Created', value: 'createdAt' },
            { text: 'CSR', value: 'createdBy' },
            { text: 'Num Items', value: 'numItems' },
            { text: 'Grand Total', value: 'metadata.grandTotal' },
          ],
          items: []
        },
        returnTable: {
          headers: [
            {text: 'Return #', align: 'start', value: 'id'},
            { text: 'Status', value: 'status' },
            { text: 'Date Created', value: 'createdAt' },
            { text: 'CSR', value: 'createdBy' },
            { text: 'CN', value: 'cnId' },
            { text: 'Grand Total', value: 'totalValue' },
          ],
          items: []
        },
        paymentTable: {
          headers: [
            { text: 'Order Ref # ', value: 'orderId' },
            {text: 'Date', align: 'start', value: 'createdAt'},
            { text: 'CSR', value: 'receivedBy' },
            { text: 'Amount', value: 'amount' },
          ],
          items: []
        },
        creditNoteTable: {
          headers: [
            {text: 'CN #', align: 'start', value: 'id'},
            { text: 'Status', value: 'status' },
            {text: 'Date', align: 'start', value: 'createdAt'},
            { text: 'CSR', value: 'issuedBy' },
            { text: 'Amount', value: 'amount' },
            {text: 'Return Ref.', align: 'start', value: 'metadata.returnId'},
          ],
          items: []
        },
        viewPaymentDialog: {
          isOpen: false,
          paymentToDisplay: {},
        },
        viewCNDialog: {
          isOpen: false,
          cn: {},
        },
        printDialog: {
          isOpen: false,
          quantity: 1,
          jobType: '',
          deliveryNote: '',
          printer: '',
        },
        printPreviewDialog: {
          isOpen: false,
          data: '',
          loading: false,
        },
      }
    },
    async mounted(){
        try {
          let res = await axios.get(`${this.getEndpoint}/api/customers/withHistory/${this.$route.params.id}`)
          if(res.data.error) throw res.data.error 
          this.cus = res.data.data
          this.purchaseTable.items = res.data.data.Orders
          this.returnTable.items = res.data.data.Returns
          this.creditNoteTable.items = res.data.data.CreditNotes
          let temp = []
          res.data.data.Orders.forEach(order=>{
            if(order.Transactions){
              temp = temp.concat(order.Transactions)
            }
          })
          this.paymentTable.items = temp
          if(this.$route.query.cn)
            this.creditNoteTableRowClick(this.creditNoteTable.items.find(x=>x.id==this.$route.query.cn))
          else if(this.$route.query.payment)
            this.paymentTableRowClick(this.paymentsTable.items.find(x=>x.id==this.$route.query.payment))
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        } finally {
          this.loader = false;
        }
    },
    computed: {
      ...mapGetters(['getEndpoint', 'isAllowed', 'lookupUsername', 'lookupPaymentType','getGlobalValue'])
    },
    methods: {
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      },
      formatPrice(value){
        if(value===null) return "";
        let formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD'
        });
        return formatter.format(parseFloat(value));
      },
      async updateCustomer(){
        try {
          this.loader = true;
          let res = await axios.put(`${this.getEndpoint}/api/customers/${this.$route.params.id}`, this.cus)
          if(res.data.error) throw res.data.error

          if(res.data.success){
            this.snack("Customer Updated 🎉", "success");
            this.isEditable = false;
          }
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        } finally {
          this.loader = false;
        }
      },
      async deleteCustomer(){
        try {
          this.loader = true
          let res = await axios.delete(`${this.getEndpoint}/api/customers/${this.$route.params.id}`)
          if(res.data.error) throw res.data.error

          this.snack("Customer Deleted 🎉", "success");
          await this.$router.go(-1)
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        }
      },
      async saveCustomAddress(){
        try{
          let res = await axios.put(`${this.getEndpoint}/api/customers/addAddress/${this.$route.params.id}`, this.newAddress)
          if(res.data.error) throw res.data.error

          if(res.data.success) {
            this.snack("Address added successfully!", "success");
            this.cus.addresses.push(this.newAddress);
            this.closeCustomAddressDialog();
          }
        }
        catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        }
      },
      closeCustomAddressDialog(){
        this.newAddress = {};
        this.saveCustomAddressDialog = false;
      },
      async deleteAddress(i){
        try {
          this.loader = true
          let res = await axios.put(`${this.getEndpoint}/api/customers/removeAddress/${this.$route.params.id}/${i}`)
          if(res.data.error) throw res.data.error

          this.snack("Address Deleted 🎉", "success");
          this.cus.addresses.splice(i, 1);
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        } finally{
          this.loader=false;
        }
      },
      paymentTableRowClick(e){
        this.viewPaymentDialog.paymentToDisplay = e
        this.viewPaymentDialog.isOpen = true
        console.log(this.viewPaymentDialog)
        this.$router.replace({'query': null});
      },
      closeViewPaymentDialog(){
        this.viewPaymentDialog.isOpen = false
        this.viewPaymentDialog.paymentToDisplay = {}
      },
      creditNoteTableRowClick(e){
        this.viewCNDialog.cn = e
        this.viewCNDialog.isOpen = true
        console.log(this.viewCNDialog)
        this.$router.replace({'query': null});
      },
      closeViewCNDialog(){
        this.viewCNDialog.isOpen = false
        this.viewCNDialog.cn = {}
      },
      purchaseTableRowClick(row){
        this.$router.push({path: `/orders/view/${row.id}`})
      },
      returnTableRowClick(e){
        this.$router.push({path: `/returns/view/${e.id}`})
      },
      parseDeliveryStatus(id){
        if(id===-1) return "Voided"
        else if(id===0) return "Draft"
        else if(id===1) return "Sealed"
        else if(id===2) return "Delivered"
      },
      parseCNStatus(id){
        if(id===-1) return "Voided"
        else if(id===0) return "Draft"
        else if(id===1) return "Unredeemed"
        else if(id===2) return "Redeemed"
      },
      parseOrderStatus(id){
        if(id===-1) return "Voided"
        else if(id===0) return "Draft"
        else if(id===1) return "Sealed"
        else if(id===2) return "Admin Sealed"
        else if(id===3) return "Delivery Scheduled"
        else if(id===4) return "Pending Reschedule"
        else if(id===5) return "Out For Delivery"
        else if(id===6) return "Delivered"
      },
      formatDate(d, type="short"){
        if(!d) return "None";
        if(type=="short")
          return (new Date(d)).toLocaleDateString('en-GB')
        if(type=="long")
          return (new Date(d)).toLocaleDateString('en-US', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
      },
      openCloudPrintDialog(){
        this.cloudPrintDialog.isOpen = true
      },
      async printDirect(type, metadata){
        try {
          let x = await axios.post(`${this.getEndpoint}/api/print/preview/${type}/${metadata.id}`)
          if(x.data.error) throw x.data.error
          let printWindow = open("","Printing")
          printWindow.document.write(x.data.data.job.htmlToPrint)
          printWindow.setTimeout(()=>{
            printWindow.print()
            printWindow.close()
          },300)
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        }
      },      
      async printPreview(type, metadata){
        try {
          this.printPreviewDialog.loading = true
          let x = await axios.post(`${this.getEndpoint}/api/print/preview/${type}/${metadata.id}`)
          if(x.data.error) throw x.data.error
          this.printPreviewDialog.data = x.data.data
          this.printPreviewDialog.loading = false
          this.printPreviewDialog.isOpen = true
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        }
      },
      closePrintPreviewDialog(){
        this.printPreviewDialog.loading = false
          this.printPreviewDialog.data = ''
          this.printPreviewDialog.isOpen = false
      },
      closePrintDialog(){
        this.cloudPrintDialog = {
          isOpen: false,
          quantity: 1,
          jobType: '',
          deliveryNote: '',
          printer: ''
        }
      },
    }
  }
</script>
